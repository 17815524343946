<template>
  <AppPageCard>
    <template #card-title> Inventory Archived</template>
    <b-table
      class="table-head-custom table-vertical-center"
      :items="item"
      :fields="fields"
    >
    </b-table>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import dayjs from "dayjs";

export default {
  name: "CompanyInventoryArchivedPage",
  components: { AppPageCard },
  data() {
    return {
      item: [
        { name: "Laptop", cat: "IT", date: dayjs().format("DD/MM/YYYY") },
        { name: "PC's", cat: "IT", date: dayjs().format("DD/MM/YYYY") },
        { name: "Tablet", cat: "IT", date: dayjs().format("DD/MM/YYYY") },
        { name: "Chair", cat: "Others", date: dayjs().format("DD/MM/YYYY") },
        { name: "Table", cat: "Others", date: dayjs().format("DD/MM/YYYY") },
      ],
      fields: [
        { key: "name", label: this.$t("EMPLOYEETABLE.DEPARTMENTNAME") },
        { key: "cat", label: "Category" },
        { key: "date", label: "Date" },
      ],
    };
  },
};
</script>

<style scoped></style>
